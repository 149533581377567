html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  overflow: hidden;
  font-size: 20px;
  font-family: -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

}

hr {
  width: 60vw;
  border-top-color: rgba(117, 129, 134, .45);
  border-top-width: .1em;
}

hr.separator {
  margin: 3em 0;
}

a,
a:hover,
a:focus,
a:visited {
  color: inherit;
  text-decoration: none;
}


/**
 * Base layout
 */

.content,
.layout {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layout {
  position: absolute;
  height: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: 24px 0;
  justify-content: space-bettwen;
}
.content {
  flex-grow: 2;
  justify-content: center;
  min-width: 260px;
}

/**
 * Text helpers
 */


.smile-text {
  letter-spacing: .15em;
}

.smile-title {
  letter-spacing: .25em;
}

.smaller {
  font-size: .6em;
}

/**
 * Layout helpers
 */

.centered-content {
  position: absolute;
  height: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.col {
  display: flex;
  flex-direction: column;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.h100 {
  height: 100%;
}
.h70 {
  height: 70%;
}
.h35 {
  height: 35%;
}

.unvisible {
  visibility: hidden;
}

.hidden {
  display: none;
}

/**
 * Footer
 */

.actions {
  font-size: 3rem;
  font-size: 4.5vh;
}
